@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";
@use "../abstracts/border-radius";

.o-footer {
	$o-footer: &;

	&--contact {
		#{o-footer}__spacing {
			padding: functions.torem(30px) 0;
			@include media-query.breakpoint(smallg) {
				padding: functions.torem(100px) 0;
			}
		}
	}

	&__spacing {
		padding: functions.torem(30px) 0;
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(100px) 0 functions.torem(70px);
		}

		&--genereal {
			padding: functions.torem(50px) 0 functions.torem(30px);
			@include media-query.breakpoint(smallg) {
				padding: functions.torem(80px) 0 functions.torem(50px);
			}
		}
	}

	&__top {
		position: relative;
	}

	&__topimage {
		position: relative;
		max-width: functions.torem(500px);
		margin: auto;
		text-align: center;
	}

	&__tophuman {
		position: relative;
		top: functions.torem(20px);
		left: 8%;
		max-width: functions.torem(250px);
		@include media-query.breakpoint(smallg) {
			left: 24%;
		}
	}

	&__topbackpink {
		position: absolute;
		top: -40%;
		right: 0;
		left: 0;
		margin: auto;
		opacity: .5;
	}

	&__largeshape {
		position: absolute;
		top: functions.torem(140px);
		right: 0;
		left: functions.torem(15px);
		width: functions.torem(200px);
		margin: auto;
	}

	&__mshape {
		position: absolute;
		top: 0;
		left: functions.torem(-30px);
		width: functions.torem(140px);
	}

	&__smshape {
		position: absolute;
		width: functions.torem(26px);

		&--one {
			top: 10%;
			left: 30%;
		}

		&--two {
			top: 25%;
			right: 26%;
		}
	}

	&__sxshape {
		position: absolute;
		top: 40%;
		left: 30%;
		width: functions.torem(14px);
	}

	&__pinkcircle {
		position: absolute;
		top: 50%;
		right: 15%;
	}

	&__pinkcircle1 {
		position: absolute;
		bottom: functions.torem(-18px);
		left: 20%;
		width: functions.torem(160px);
		opacity: .6;
	}

	&__footerwave {
		position: absolute;
		right: 0;
		bottom: functions.torem(-5px);
		left: 0;
		z-index: z-index.$on-top;
		margin: auto;
		@include media-query.breakpoint(smallg) {
			bottom: functions.torem(-80px);
		}
	}

	&__paragraph {
		max-width: functions.torem(530px);
		margin: 0 auto functions.torem(30px);
		color: colors.$white;
		font-family: fonts.$font-family--heading;
		font-size: functions.torem(14px);
		line-height: functions.torem(28px);
		text-align: center;
		text-transform: uppercase;
	}

	&__bottom {
		position: relative;
		z-index: z-index.$on-top;
		@include media-query.breakpoint(smallg) {
			margin: functions.torem(60px) 0 0;
			border-radius: border-radius.$border-radius--box;
		}

		&--genereal {
			margin: 0;
		}
	}

	&__bottomtop {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}

	&__bottomlogo {
		max-width: functions.torem(190px);
		margin: auto;
		@include media-query.breakpoint(smallg) {
			margin: 0 0 functions.torem(25px);
		}
	}

	&__bottommenu {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: functions.torem(10px);
		margin: 0 0 functions.torem(70px);
		padding: 0;
		@include media-query.breakpoint(smallg) {
			gap: 0;
			margin: 0;
		}
		@include media-query.breakpoint(medium) {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	&__menuitem {
		margin: 0 functions.torem(7px);
		@include media-query.breakpoint(smallg) {
			margin: 0 0 0 functions.torem(40px);
		}
	}

	&__menuitems {
		@include fonts.font-menu-anchor--size;
		color: colors.$white;
		text-transform: none;
		text-decoration: none;

		&:hover {
			color: colors.$primary-color;
		}  
	}

	&__bottomdown {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		margin-top: functions.torem(20px);
		text-align: center;
		@include media-query.breakpoint(smallg) {
			flex-direction: row;
			margin-top: functions.torem(35px);
			text-align: left;
		}	
	}

	&__bottomcontent {
		@include fonts.font-size--small;
		color: colors.$body-color;

		&--link {
			width: 50%;
			margin-top: functions.torem(15px);
			@include media-query.breakpoint(smallg) {
				display: block;
				width: auto;
				margin-top: 0;
				margin-left: functions.torem(30px);
			}

			&:hover {
				color: colors.$primary-color;
			}
		}

		&--country {
			display: none;
			text-align: center;
			text-transform: fonts.$uppercase;
			@include media-query.breakpoint(smallg) {
				display: none;
			}
		}
	}

	&__bottomdownright {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		@include media-query.breakpoint(smallg) {
			width: 70%;
			justify-content: flex-end;
			flex-wrap: nowrap;
		}
	}

	&__sociallist {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: functions.torem(14px);
		@include media-query.breakpoint(smallg) {
			width: 30%;
			justify-content: flex-end;
			margin-top: 0;
			margin-left: functions.torem(20px);
		}
	}

	&__socialitem {
		margin-right: functions.torem(5px);
		margin-left: functions.torem(5px);
		@include media-query.breakpoint(smallg) {
			margin-right: 0;
			margin-left: functions.torem(10px);
		}
	}

	&__socialitems {
		&:hover {
			img {
				filter: invert(.7) sepia(1) saturate(5) hue-rotate(250deg);
			}
		}
	}

	&__wrapper-right {
		max-width: 100%;
		margin-left: auto;
		@include media-query.breakpoint(medium) {
			max-width: 49%;
		}
	}

	&__wrapper-heading {
		margin-bottom: functions.torem(15px);
		color: colors.$white;
		font-family: fonts.$font-family--heading;
		font-size: functions.torem(24px);
		font-weight: fonts.$font-weight--black;
		line-height: normal;
		text-align: center;
		text-transform: capitalize;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(25px);
			font-size: functions.torem(44px);
			line-height: functions.torem(53px);
			text-align: left;
		}
	}

	&__wrapper-subheading {
		margin-bottom: functions.torem(15px);
		color: colors.$white;
		font-family: fonts.$font-family--heading;
		font-size: functions.torem(16px);
		text-align: center;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(25px);
			padding-right: functions.torem(70px);
			font-size: functions.torem(20px);
			text-align: left;
		}
	}

	&__formwrap {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		@include media-query.breakpoint(smallg) {
			justify-content: space-between;
			flex-wrap: nowrap;
		}
	}

	&__formgroup {
		width: 100%;
		margin: 0 0 functions.torem(20px);
		@include media-query.breakpoint(smallg) {
			width: 48%;
		}

		&--phone {
			@include media-query.breakpoint(smallg) {
				width: 52%;
			}
		}

		&--full {
			@include media-query.breakpoint(smallg) {
				width: 100%;
			}
		}
	}

	&__formlabel {
		margin: 0 0 functions.torem(10px);
		color: colors.$white;
		font-size: functions.torem(12px);
		line-height: normal;
		display: block;
	}

	&__formnumber {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		gap: functions.torem(5px);
	}

	&__input {
		width: 100%;
		height: functions.torem(45px);
		margin: 0;
		color: colors.$black;
		font-family: fonts.$font-family--heading;
		font-size: functions.torem(16px);
		background-color: colors.$white;
		border: functions.torem(1px) solid colors.$border-color5;
		border-radius: border-radius.$border-radius--sbtn;

		&--textarea {
			height: functions.torem(90px);
			resize: none;
			padding: functions.torem(20px);
		}

		&--countrycode {
			width: functions.torem(50px);
			padding: functions.torem(9px);
		}

		&--number {
			appearance: textfield;
			appearance: none;
			appearance: none; 
		}

		&::-webkit-outer-spin-button {
			appearance: none;
			margin: 0;
		}

		&::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}

		&::placeholder {
			color: rgb(176, 176, 176);
			opacity: 1;
		}
	}

	&__btn {
		position: relative;
		display: flex;
		width: functions.torem(248px);
		height: functions.torem(68px);
		justify-content: center;
		align-items: center;
		margin: functions.torem(25px) auto functions.torem(50px);
		color: colors.$white;
		font-size: functions.torem(20px);
		font-weight: fonts.$font-weight--bolder;
		background: rgb(216, 61, 255);
		background: radial-gradient(circle, rgba(216, 61, 255, 1) 3%, rgba(138, 86, 248, 1) 100%);
		border: 0;
		border-radius: functions.torem(106px);
		transition: .5s all ease-in-out;
		cursor: pointer;
		border: 1px solid;
		border-color: transparent;
		@include media-query.breakpoint(smallg) {
			margin: functions.torem(25px) 0 0;
		}
		&--contact{
			@include media-query.breakpoint(smallg) {
				width: functions.torem(180px);
				height: functions.torem(50px);
				font-size: functions.torem(16px);
				margin: 0 auto;
			}
		}
		&:hover{
			background: colors.$white;
			color: colors.$primary-color;
			border-color: colors.$primary-color;
		}
	}
}

.error-message {
	width: 100%;
	margin-top: functions.torem(8px);
	color: colors.$red;
	font-size: functions.torem(14px);
	text-align: left;
}
.form-success-message{
	color: colors.$green;
    padding: functions.torem(10px) functions.torem(30px);
    background-color: rgba(224,242,233,1);
    border-radius: functions.torem(8px);
    margin: functions.torem(20px) 0;
    width: auto;
    display: inline-block;
    border: functions.torem(5px) solid colors.$white;
}
.form-error-message{
	color: colors.$red;
	padding: functions.torem(10px) functions.torem(30px);
	background-color: rgba(255,235,238,1); 
	border-radius: functions.torem(8px);
    margin: functions.torem(20px) 0;
    width: auto;
    display: inline-block;
    border: functions.torem(5px) solid colors.$white;
}
.error-form-message{
	width: 100%;
	margin: functions.torem(10px) 0 0;
	color: colors.$red;
	font-size: functions.torem(14px);
	line-height: functions.torem(14px);
	text-align: left;
}
