@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";

.o-feature {
	$o-feature: &;

	position: relative;
	background-color: colors.$bgcolor;
	padding: functions.torem(30px) 0 functions.torem(90px);
	@include media-query.breakpoint(smallg) {
		padding: functions.torem(100px) 0 functions.torem(150px);
	}

	&__background{
		position: absolute;
		left: 0;
		top: -70%;
		right: 0;
		margin: auto;
		width: 100%;
		
		@include media-query.breakpoint(elarge) {
			top: -80%;
		}
		@include media-query.breakpoint(extralarge) {
			top: -95%;
		}
		@include media-query.breakpoint(extratolarge) {
			top: -102%;
		}
	}

	// &::before {
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: functions.torem(2000px);
	// 	margin: 0 auto;
	// 	background: url(../images/feature-bg.webp) no-repeat top center;
	// 	background-size: 100%;
	// }

	&__wrapper {
		@include media-query.breakpoint(medium) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
		}
	}

	&__card {
		margin: 0 0 functions.torem(13px);
		padding: functions.torem(30px) functions.torem(10px);
		background: rgb(57,146,228);
		background: linear-gradient(0deg, rgba(57,146,228,1) 0%, rgba(11,60,135,1) 100%);
		border-radius: functions.torem(30px);
		cursor: pointer;
		text-decoration: none;
		backdrop-filter: blur(24px);
		@include media-query.breakpoint(smallg) {
			flex: 45%;
			margin: functions.torem(8px);
		}
		@include media-query.breakpoint(ipad) {
			height: functions.torem(245px);
			flex: 1;
			margin: 0 functions.torem(13px);
			padding: functions.torem(40px) 0 functions.torem(20px) 0;
		}
	}

	&__card-name {
		margin-top: functions.torem(25px);
		padding: 0 functions.torem(15px);
		color: colors.$white;
		font-family: fonts.$font-family--heading;
		font-size: functions.torem(20px);
		font-weight: fonts.$font-weight--medium;
		line-height: normal;
		text-align: center;
		@include media-query.breakpoint(smallg) {
			font-size: functions.torem(18px);
			font-weight: fonts.$font-weight--normal;
		}
	}

	&__card-icon {
		display: flex;
		height: functions.torem(90px);
		justify-content: center;
		align-items: flex-start;
		margin: 0 auto;
		text-align: center;
	}

	&__d-lg-block {
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}
	.splide{
		z-index: z-index.$overlay;
	}
	.splide__arrows {
		position: absolute;
		right: 0;
		bottom: functions.torem(-35px);
		left: 0;
		width: functions.torem(110px);
		margin: auto;
	}

	&__backperticle{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: z-index.$on-top;
	}

	&__backperticle1{
		position: absolute;
		top: functions.torem(-50px);
		right: functions.torem(-10px);
	}

	&__backperticle2{
		position: absolute;
		bottom: functions.torem(-50px);
		left: 20%;
	}

	&__backperticle3{
		position: absolute;
		left: 30%;
		top: functions.torem(-70px);
	}
}
